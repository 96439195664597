* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 0px;
}

::-webkit-scrollbar-thumb {
  background: rgb(107, 107, 107);
  border-radius: 0px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(72, 71, 71);
}
